import { AxiosInstance, AxiosRequestConfig, Method } from "axios"

export type EndUserType = "B2B" | "B2C" | "C2B" | "C2C"

export const END_USER_TYPES: EndUserType[] = ["B2B", "B2C", "C2B", "C2C"]

export type ShippingType =
  | "STATION_TO_STATION"
  | "STATION_TO_LM"
  | "LM_TO_STATION"
  | "LM_TO_LM"

export const SHIPPING_TYPES: ShippingType[] = [
  "STATION_TO_STATION",
  "STATION_TO_LM",
  "LM_TO_STATION",
  "LM_TO_LM",
]

export type DeliveryClass =
  | "TO_19" // 저녁도착
  | "TO_24" // 오늘도착
  | "TO_48" // 내일도착
  | "FROM_72" // 빠른회수
  | "INCHEON_24" // 인천 오늘도착
  | "INCHEON_48" // 인천 내일도착
  | "FORWARDING_24" // 택배위탁
  | "TOGETHER_48" // 익일 투게더 (B2B)
  | "RETURNING" // 빠른반품

export const DELIVERY_CLASSES: DeliveryClass[] = [
  //"TO_19",
  "TO_24",
  "TO_48",
  //"FROM_72",
  "INCHEON_24",
  "INCHEON_48",
  //"TOGETHER_48",
  "FORWARDING_24",
  "RETURNING",
]

export const DELIVER_DELIVERY_CLASSES: DeliveryClass[] = [
  "TO_19",
  "TO_24",
  "TO_48",
  "INCHEON_24",
  "INCHEON_48",
  "TOGETHER_48",
]

export const PICK_UP_DELIVERY_CLASSES: DeliveryClass[] = [
  "FORWARDING_24",
  "RETURNING",
]

export type FailedAlimtalkResolveType = "DELIVERED" | "QUIT"
export const ALIMTALK_RESOLVE_TYPES: FailedAlimtalkResolveType[] = [
  "DELIVERED",
  "QUIT",
]

export type LoadSize = "소" | "중" | "대" | "특대" | "이형" | ""

export interface PhysicalAttributes {
  size: LoadSize
  width?: number
  depth?: number
  height?: number
  weight?: number
  fragile?: boolean
  fresh?: boolean
}

export type TodayAPIRequestConfig = Omit<
  AxiosRequestConfig,
  "url" | "method" | "data"
>

export class TodayAPIClient {
  client: AxiosInstance

  constructor(client: AxiosInstance) {
    this.client = client
  }

  private fetcherFunc<T = any>(
    url: string,
    method?: Method,
    data?: any,
    config?: TodayAPIRequestConfig
  ): Promise<T>
  private fetcherFunc<T = any>(
    url: string,
    data?: Exclude<any, Method>,
    config?: TodayAPIRequestConfig
  ): Promise<T>

  private async fetcherFunc<T = any>(
    url: string,
    arg1?: Method | any,
    arg2?: any | TodayAPIRequestConfig,
    arg3?: TodayAPIRequestConfig
  ) {
    const method =
      typeof arg1 === "string" ? arg1 : arg1 === undefined ? "GET" : "POST"
    const data = typeof arg1 === "string" ? arg2 : arg1
    const config = typeof arg1 === "string" ? arg3 : arg2

    const resp = await this.client.request<T>({
      method: method as Method,
      url,
      data,
      ...(config ? { config } : {}),
    })
    return resp.data
  }

  fetcher<T = any>(): {
    (
      url: string,
      data?: Exclude<any, Method>,
      config?: TodayAPIRequestConfig
    ): Promise<T>
    (
      url: string,
      method?: Method,
      data?: any,
      config?: TodayAPIRequestConfig
    ): Promise<T>
  } {
    return this.fetcherFunc.bind(this)
  }
}
